import { useBonusesStore } from './useBonusesStore'
import { useFreebetsStore } from './useFreebetsStore'
import { useFreespinsStore } from './useFreespinsStore'
import { useDepositBonusesStore } from './useDepositBonusesStore'

export const useBonusesCountStore = defineStore('bonusesCount', () => {
  const { count: freebetsCount } = storeToRefs(useFreebetsStore())
  const { count: freespinsCount } = storeToRefs(useFreespinsStore())
  const { bonusesCount: regularBonusesCount } = storeToRefs(useBonusesStore())
  const { bonusesCount: depositBonusesCount } = storeToRefs(
    useDepositBonusesStore(),
  )

  const bonusesCount = computed(
    () =>
      freebetsCount.value +
      freespinsCount.value +
      regularBonusesCount.value +
      depositBonusesCount.value,
  )

  return {
    bonusesCount,
  }
})
